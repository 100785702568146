import {
  funcStructureDataFormClientStepByStep,
  funcStructureDataFormCorporateCustomerStepByStep,
  funcStructureDataFormOnlineConsultationStepByStep,
  funcStructureDataFormTrialStayStepByStep,
  funcStructureDataFormVisitReservationStepByStep,
  StructureDataFindAboutVacation
} from '@/utils/structure-data'
import { gqlErrorToast } from '@/utils/graphqlErrors'
import { CREATE_WEB_REQUEST_GQL } from '@/api/graphql/web-request'
import C from '@/utils/constant'
import router from '../../../router'
import { i18n } from '@/plugins/i18n'
import { StepToName } from '@/utils/urls'

export default {

  async createWebRequestDispatch (context, { apolloClient, typeApply, payload, toast = null }) {
    return apolloClient.mutate({
      mutation: CREATE_WEB_REQUEST_GQL,
      variables: payload,
      update: (store, { data: { createWebRequest } }) => {
        // None
      }
    }).then(res => {
      switch (typeApply) {
        case 'CLIENT':
          context.dispatch('stepClientDocRequestTypeDispatch', C.STEP_BY_STEP.STEP_3)
          break
        case 'CORPORATE':
          context.dispatch('stepCorporateCustomerTypeDispatch', C.STEP_BY_STEP.STEP_3)
          break
        case 'VISIT_RESERVATION':
          context.dispatch('stepVisitReservationTypeDispatch', C.STEP_BY_STEP.STEP_3)
          break
        case 'ONLINE_CONSULTATION':
          context.dispatch('stepOnlineConsultationTypeDispatch', C.STEP_BY_STEP.STEP_3)
          break
        case 'TRIAL_STAY':
          context.dispatch('stepTrialStayTypeDispatch', C.STEP_BY_STEP.STEP_3)
          break
      }

      return true
    }).catch((error) => {
      gqlErrorToast(toast, error)

      switch (typeApply) {
        case 'CLIENT':
          context.dispatch('stepClientDocRequestTypeDispatch', C.STEP_BY_STEP.STEP_1)
          break
        case 'CORPORATE':
          context.dispatch('stepCorporateCustomerTypeDispatch', C.STEP_BY_STEP.STEP_1)
          break
        case 'VISIT_RESERVATION':
          context.dispatch('stepVisitReservationTypeDispatch', C.STEP_BY_STEP.STEP_1)
          break
        case 'ONLINE_CONSULTATION':
          context.dispatch('stepOnlineConsultationTypeDispatch', C.STEP_BY_STEP.STEP_1)
          break
        case 'TRIAL_STAY':
          context.dispatch('stepTrialStayTypeDispatch', C.STEP_BY_STEP.STEP_1)
          break
      }

      return false
    })
  },
  async stepClientDocRequestTypeDispatch ({ commit, state, rootState }, payload) {
    router.push({ name: i18n.t('routeTitles.documentRequest.client'), params: { step: StepToName[payload] } })
    commit('stepClientDocRequestTypeMutation', payload)
  },
  async stepCorporateCustomerTypeDispatch ({ commit, state, rootState }, payload) {
    router.push({ name: i18n.t('routeTitles.documentRequest.corporateCustomer'), params: { step: StepToName[payload] } })
    commit('stepCorporateCustomerTypeMutation', payload)
  },
  async stepTrialStayTypeDispatch ({ commit, state, rootState }, payload) {
    router.push({ name: i18n.t('routeTitles.trialStay.name'), params: { step: StepToName[payload] } })
    commit('stepTrialStayTypeMutation', payload)
  },
  async stepOnlineConsultationTypeDispatch ({ commit, state, rootState }, payload) {
    router.push({ name: i18n.t('routeTitles.onlineConsultation.name'), params: { step: StepToName[payload] } })
    commit('stepOnlineConsultationTypeMutation', payload)
  },
  async stepVisitReservationTypeDispatch ({ commit, state, rootState }, payload) {
    router.push({ name: i18n.t('routeTitles.visitReservation.name'), params: { step: StepToName[payload] } })
    commit('stepVisitReservationTypeMutation', payload)
  },
  async policyAgreeDispatch ({ commit, state, rootState }, { policyAgree, typeApply }) {
    switch (typeApply) {
      case 'CLIENT': {
        const clone = { ...state.dataClientDocRequest }
        clone.policyAgree = policyAgree

        commit('dataFormDocRequestMutation', funcStructureDataFormClientStepByStep(clone))
        break
      }
      case 'CORPORATE': {
        const clone = { ...state.dataCorporateCustomer }
        clone.policyAgree = policyAgree

        commit('dataCorporateCustomerMutation', funcStructureDataFormCorporateCustomerStepByStep(clone))
        break
      }
      case 'VISIT_RESERVATION': {
        const clone = { ...state.dataVisitReservation }
        clone.policyAgree = policyAgree

        commit('dataVisitReservationMutation', funcStructureDataFormVisitReservationStepByStep(clone))
        break
      }
      case 'ONLINE_CONSULTATION': {
        const clone = { ...state.dataOnlineConsultation }
        clone.policyAgree = policyAgree

        commit('dataOnlineConsultationMutation', funcStructureDataFormOnlineConsultationStepByStep(clone))
        break
      }
      case 'TRIAL_STAY': {
        const clone = { ...state.dataTrialStay }
        clone.policyAgree = policyAgree

        commit('dataTrialStayMutation', funcStructureDataFormTrialStayStepByStep(clone))
        break
      }
      default:
    }
  },
  async requestForMemberDispatch ({ commit, state, rootState }, payload) {
    const clone = { ...state.dataClientDocRequest }
    clone.requestForMember = Object.keys(payload).length ? payload : clone.requestForMember

    commit('dataFormDocRequestMutation', funcStructureDataFormClientStepByStep(clone))
  },
  async requestForCorporateDispatch ({ commit, state, rootState }, payload) {
    const clone = { ...state.dataCorporateCustomer }
    clone.requestForCorporate = Object.keys(payload).length ? payload : clone.requestForCorporate

    commit('dataCorporateCustomerMutation', funcStructureDataFormCorporateCustomerStepByStep(clone))
  },
  async findAboutVacationDispatch ({ commit, state, rootState }, { payload, typeApply }) {
    switch (typeApply) {
      case 'CLIENT': {
        const clone = { ...state.dataClientDocRequest }
        const dataPass = new StructureDataFindAboutVacation(payload)

        clone.findAboutVacation.finds = dataPass.finds
        clone.findAboutVacation.findBySNS = dataPass.findBySNS
        clone.findAboutVacation.findByInputOther = dataPass.findByInputOther

        commit('dataFormDocRequestMutation', funcStructureDataFormClientStepByStep(clone))
        break
      }
      case 'CORPORATE': {
        const clone = { ...state.dataCorporateCustomer }
        const dataCorporate = new StructureDataFindAboutVacation(payload)

        clone.findAboutVacation.finds = dataCorporate.finds
        clone.findAboutVacation.findBySNS = dataCorporate.findBySNS
        clone.findAboutVacation.findByInputOther = dataCorporate.findByInputOther

        commit('dataCorporateCustomerMutation', funcStructureDataFormCorporateCustomerStepByStep(clone))
        break
      }
      default:
    }
  },
  async mainFormDocClientRequestDispatch ({ commit, state, rootState }, payload) {
    const clone = { ...state.dataClientDocRequest }
    clone.mainFormDocRequest = Object.keys(payload).length ? payload : clone.mainFormDocRequest

    commit('dataFormDocRequestMutation', funcStructureDataFormClientStepByStep(clone))
  },
  async mainFormVisitReservationRequestDispatch ({ commit, state, rootState }, payload) {
    const clone = { ...state.dataVisitReservation }
    clone.mainFormDocRequest = Object.keys(payload).length ? payload : clone.mainFormDocRequest

    commit('dataVisitReservationMutation', funcStructureDataFormVisitReservationStepByStep(clone))
  },
  async mainFormVisitOptionalRequestDispatch ({ commit, state, rootState }, payload) {
    const clone = { ...state.dataVisitReservation }
    clone.visitReservationOptional = Object.keys(payload).length ? payload : clone.visitReservationOptional

    commit('dataVisitReservationMutation', funcStructureDataFormVisitReservationStepByStep(clone))
  },
  async mainFormOnlineConsultationRequestDispatch ({ commit, state, rootState }, payload) {
    const clone = { ...state.dataOnlineConsultation }
    clone.mainFormDocRequest = Object.keys(payload).length ? payload : clone.mainFormDocRequest

    commit('dataOnlineConsultationMutation', funcStructureDataFormOnlineConsultationStepByStep(clone))
  },
  async mainFormOnlineOptionalRequestDispatch ({ commit, state, rootState }, payload) {
    const clone = { ...state.dataOnlineConsultation }
    clone.onlineConsultationOptional = Object.keys(payload).length ? payload : clone.onlineConsultationOptional

    commit('dataOnlineConsultationMutation', funcStructureDataFormOnlineConsultationStepByStep(clone))
  },
  async mainFormTrialStayRequestDispatch ({ commit, state, rootState }, payload) {
    const clone = { ...state.dataTrialStay }
    clone.mainFormDocRequest = Object.keys(payload).length ? payload : clone.mainFormDocRequest

    commit('dataTrialStayMutation', funcStructureDataFormTrialStayStepByStep(clone))
  },
  async trialStayOptionalRequestDispatch ({ commit, state, rootState }, payload) {
    const clone = { ...state.dataTrialStay }
    clone.trialStayOptional = Object.keys(payload).length ? payload : clone.trialStayOptional

    commit('dataTrialStayMutation', funcStructureDataFormTrialStayStepByStep(clone))
  },
  async mainFacilityInterestedDispatch ({ commit, state, rootState }, { payload, typeApply }) {
    const { reqForMemberOptional1, reqForMemberOptional2 } = payload
    switch (typeApply) {
      case 'CLIENT': {
        const clone = { ...state.dataClientDocRequest }
        clone.facilityInterestedOption1 = reqForMemberOptional1 && Array.isArray(reqForMemberOptional1) ? reqForMemberOptional1 : clone.facilityInterestedOption1
        clone.facilityInterestedOption2 = reqForMemberOptional2 && Array.isArray(reqForMemberOptional2) ? reqForMemberOptional2 : clone.facilityInterestedOption2

        commit('dataFormDocRequestMutation', funcStructureDataFormClientStepByStep(clone))
        break
      }
      case 'CORPORATE': {
        const clone = { ...state.dataCorporateCustomer }
        clone.facilityInterestedOption1 = reqForMemberOptional1 && Array.isArray(reqForMemberOptional1) ? reqForMemberOptional1 : clone.facilityInterestedOption1
        clone.facilityInterestedOption2 = reqForMemberOptional2 && Array.isArray(reqForMemberOptional2) ? reqForMemberOptional2 : clone.facilityInterestedOption2

        commit('dataCorporateCustomerMutation', funcStructureDataFormCorporateCustomerStepByStep(clone))
        break
      }
      default:
    }
  },
  async mainFormCorporateDispatch ({ commit, state, rootState }, payload) {
    const clone = { ...state.dataCorporateCustomer }
    clone.mainFormCorporate = Object.keys(payload).length ? payload : clone.mainFormCorporate

    commit('dataCorporateCustomerMutation', funcStructureDataFormCorporateCustomerStepByStep(clone))
  }
}

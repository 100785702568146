const fromString = process.env.VUE_APP_MAINTENANCE_FROM
const toString = process.env.VUE_APP_MAINTENANCE_TO

export function isInMaintenance () {
  if (!fromString || !toString) {
    return false
  }
  const from = new Date(fromString)
  const to = new Date(toString)
  const now = new Date()

  return now >= from && now <= to
}

export function getMaintenanceMessage () {
  return process.env.VUE_APP_MAINTENANCE_MESSAGE
}

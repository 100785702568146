import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import documentRequest from './modules/documentRequest'
import pointExchangeConfirm from './modules/pointExchangeConfirm'
import main from './modules/main'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    documentRequest,
    main,
    pointExchangeConfirm
  },
  getters: getters
})

export default store

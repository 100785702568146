import gql from 'graphql-tag'

export const PUBLIC_FACILITY_LIST_GQL = gql`
  query {
    publicFacilityList  {
      id
      name
    }
  }
`

export const FACILITY_INTERESTED_LIST_GQL = gql`
  query {
    facilityInterestedList  {
      id
      name
    }
  }
`

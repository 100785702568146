import { i18n } from '@/plugins/i18n'

const pointExchangeConfirmRouter = [
  {
    path: '/point-exchange-confirm',
    name: i18n.t('routeTitles.pointExchangeConfirm.name'),
    component: () => import('../../views/pointExchangeConfirm')
  }
]

export default pointExchangeConfirmRouter

import axios from 'axios'
import store from '../store'

export default function (options = {}, isExtentURL = false) {
  const {
    headers = {}
  } = options

  // Create an axios instance
  const service = axios.create({
    ...!isExtentURL && { baseURL: process.env.VUE_APP_API_END_POINT_URL },
    ...isExtentURL && { baseURL: process.env.VUE_APP_API_POSTAL_CODE_ENDPOINT },
    timeout: 5000, // request timeout
    headers: {
      'Content-Type': 'application/json',
      ...headers
    }
  })

  // request interceptor
  service.interceptors.request.use(serviceConfig => {
    if (store.getters.token) {
      // serviceConfig.headers['X-Token'] = token();
    }

    return serviceConfig
  }, error => {
    Promise.reject(error)
  })

  // response interceptor
  service.interceptors.response.use(response => {
    const res = response.data
    if (response.status !== 200) {
      return {
        message: res.message,
        type: 'error',
        duration: 5 * 1000
      }
    }
    return res
  }, error => {
    // {
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000,
    // }
    return Promise.resolve(error)
  })

  return service
}

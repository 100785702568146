import Vue from 'vue'
import VueRouter from 'vue-router'
import PageNotFound from '../views/pageNotFound'

import indexRouter from './modules'
import store from '../store'
import checkMaintenance from './modules/check-maintenance'

Vue.use(VueRouter)

const routes = [
  indexRouter,
  {
    path: '*',
    component: PageNotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  store,
  routes
})

router.beforeEach(checkMaintenance)

export default router

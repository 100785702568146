<template>
  <div>
    {{  $t('routeTitles.pageNotFound') }}
  </div>
</template>

<script>

export default {
  name: 'PageNotFound'
}
</script>

<style scoped>

</style>

import { isInMaintenance } from './app'

const maintenanceMessage = '現在サーバのメンテナンス中です。時間をおいて再度お試しください。'

export function gqlErrorToast (toast, gqlError) {
  if (gqlError?.graphQLErrors?.length && toast) {
    gqlError.graphQLErrors.forEach(error => {
      toast.error(error.message)
    })
  } else if (gqlError.message === 'Network error: Failed to fetch' && isInMaintenance()) {
    // Network error: Failed to fetch is thrown if (not only if) cors error.
    // if it is in the maintenance time, then it was probably thrown because of the maintenance,
    // show the appropriate message
    toast.error(maintenanceMessage)
  }
  if (gqlError?.networkError && toast) {
    toast.error(gqlError?.networkError?.message)
  }
}

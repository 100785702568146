import { CONFIRM_RECEIVE_OF_POINT_EXCHANGE_REQUEST_GQL } from '@/api/graphql/point-exchange-confirm'
import { gqlErrorToast } from '@/utils/graphqlErrors'

export default {

  async fetchPointExchangeConfirmDispatch (context, { apolloClient, toast, payload }) {
    apolloClient.mutate({
      mutation: CONFIRM_RECEIVE_OF_POINT_EXCHANGE_REQUEST_GQL,
      variables: payload,
      update: (store, { data: { confirmReceiveOfPointExchange } }) => {
        // None
      }
    }).then(res => {
      // Boolean
      context.commit('confirmReceiveOfPointExchangeMutation', res?.data.confirmReceiveOfPointExchange ? res.data.confirmReceiveOfPointExchange : false)
    }).catch(error => {
      gqlErrorToast(toast, error)
    })
  }
}

export default {
  common: {
    policyDescription: '本フォームによってご提供いただきましたお客様の個人情報は、東急（株）および東急ホテルズ&リゾーツ（株）が個人情報保護法に基づいて適切に管理いたしますとともに、新商品・新サービス・優待・イベント等に関する情報のご送付・ご案内等に利用させていただきます。',
    policyLabel: '上記内容に同意する（同意される場合は、チェックを入れてください）',
    labelRequired: '必須',
    labelOptional: '任意',
    notSaveWhenDbClick: '現在リクエストの処理中です。少々お待ち下さい。',
    postalCodeExample: '例：1500043（半角）',
    prefectureExample: '例：東京都',
    localityExample: '例：渋谷区',
    streetAddressExample: '例：道玄坂1-10-7',
    buildingNameExample: '例：五島育英会ビル3F',
    telExample: '例：03-3477-6025（半角）'
  },
  routeTitles: {
    documentRequest: {
      client: '資料請求フォーム',
      corporateCustomer: 'WEB資料請求'
    },
    visitReservation: {
      name: 'オンライン相談'
    },
    onlineConsultation: {
      name: 'Visit reservation form'
    },
    trialStay: {
      name: '体験宿泊申込'
    },
    pointExchangeConfirm: {
      name: 'ポイント交換受付確認'
    },
    pageNotFound: 'ページが見つかりません'
  },
  documentRequest: {
    facilityInterestedTitleOptional1: 'ご興味があるのは、どちらの施設ですか？（複数選択可）',
    facilityInterestedTitleOptional2: 'ご興味があるシーズンはいつですか？（複数選択可）',
    findAboutVacationsTitle: '東急バケーションズは何でお知りになりましたか？（複数選択可）',
    stepByStep: {
      step1: '必要事項のご入力',
      step2: '入力内容のご確認',
      step3: '送信完了'
    },
    client: {
      settingsTitle: 'WEB資料請求',
      reqForMemberTitle: 'どちらの会員の資料請求をされますか。',
      reqForMemberData: {
        primeMember: 'Vacation Masters',
        vacationsClubMember: 'Vacation Style',
        bothHope: 'どちらも希望'
      }
    },
    corporateCustomer: {
      settingsTitle: 'WEB資料請求'
    },
    visitReservation: {
      settingsTitle: 'ご来店予約'
    },
    onlineConsultation: {
      settingsTitle: 'オンライン相談'
    },
    triaStay: {
      settingsTitle: '体験宿泊申込'
    }
  },
  pointExchangeConfirm: {
    settingsTitle: 'ポイント交換受付確認'
  },
  buttons: {
    saveAndClose: '保存して閉じる',
    cancel: 'キャンセル',
    saveAndCreate: '送信する',
    request: 'リクエスト',
    back: '戻る',
    confirmAndSend: '確認して送信',
    requestItemFill: '未入力の必須項目があります'
  },
  rules: {
    data: {
      type: 'タイプ',
      year: '年',
      month: '月',
      date: '日',
      postalCode: '郵便番号',
      email: '電子メール',
      mobileNumber: '携帯電話番号',
      homePhoneNumber: '自宅電話番号',
      companyName: '会社名',
      companySize: '社員数',
      companyNameKana: '会社名フリガナ',
      companyPicDepartment: 'ご担当者部署',
      companyPicTitle: '役職名',
      businessTypeOther: 'その他と選択された方',
      companyPicName: 'ご担当者名',
      companyPicKana: 'フリガナ',
      lastName: 'お名前（姓）',
      firstName: 'お名前（名）',
      kanaLastName: 'フリガナ（セイ）',
      kanaFirstName: 'フリガナ（メイ）',
      cityOrCounty: '市区郡',
      streetBunch: '町名・番地',
      addressOther: 'ビル名・マンション名等',
      birthday: '生年月日',
      inputOther: 'その他',
      prefecture: '都道府県',
      businessType: '業種',
      phoneNumber: 'ご連絡先',
      katakana: 'フリガナ'
    },
    required: '{title}は必須項目です',
    minimumNCharacter: '{value}文字以上で入力して下さい',
    minimumN: '{value}人以上で入力して下さい',
    moreThanN: '{value}以上で入力して下さい',
    invalid: '{title}が無効な値です',
    selected: '少なくとも1つの項目を選択して下さい',
    postCodeValid: '郵便番号は半角数字（ハイフンなし）で入力してください',
    homeNumberValid: '自宅電話番号が不正な形式です',
    corporateNumberValid: '電話番号が不正な形式です',
    mobileNumberValid: '携帯電話番号が不正な形式です',
    katakana: '{title}は全角カナでご入力下さい',
    policyAgree: 'ご確認いただきご同意いただく必要がございます'
  },
  toast: {
  }
}

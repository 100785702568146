import { i18n } from '@/plugins/i18n'

const documentRequestRouter = [
  {
    path: '/document-request',
    redirect: '/document-request/client'
  }, {
    path: '/document-request/client/:step?',
    name: i18n.t('routeTitles.documentRequest.client'),
    component: () => import('../../views/documentRequest/client')
  }, {
    path: '/document-request/corporate-customer/:step?',
    name: i18n.t('routeTitles.documentRequest.corporateCustomer'),
    component: () => import('../../views/documentRequest/corporateCustomer')
  },
  {
    path: '/document-request/visit-reservation/:step?',
    name: i18n.t('routeTitles.visitReservation.name'),
    component: () => import('../../views/documentRequest/visitReservation')
  },
  {
    path: '/document-request/online-consultation/:step?',
    name: i18n.t('routeTitles.onlineConsultation.name'),
    component: () => import('../../views/documentRequest/onlineConsultation')
  },
  {
    path: '/document-request/trial-stay/:step?',
    name: i18n.t('routeTitles.trialStay.name'),
    component: () => import('../../views/documentRequest/trialStay')
  }
]

export default documentRequestRouter

export default Object.freeze({
  STEP_BY_STEP: {
    STEP_1: 'step_1',
    STEP_2: 'step_2',
    STEP_3: 'step_3'
  },
  WEB_REQUEST_FOR_TYPE_MEMBER: {
    primeMember: 'primeMember',
    vacationsClubMember: 'vacationsClubMember',
    bothHope: 'bothHope'
  },
  SNS_KEY: 'sns',
  OTHER_KEY: 'other',
  WORK_STYLE_REFORM_TRUE: 'yep_true',
  WORK_STYLE_REFORM_FALSE: 'yep_false'

})

export default {
  common: {
    policyDescription: '本フォームによってご提供いただきましたお客様の個人情報は、東急（株）および東急ホテルズ&リゾーツ（株）が個人情報保護法に基づいて適切に管理いたしますとともに、新商品・新サービス・優待・イベント等に関する情報のご送付・ご案内等に利用させていただきます。',
    policyLabel: '上記内容に同意する（同意される場合は、チェックを入れてください）',
    labelRequired: '必須',
    labelOptional: '任意',
    notSaveWhenDbClick: '現在リクエストの処理中です。少々お待ち下さい。',
    postalCodeExample: '例：1500043（半角）',
    prefectureExample: '例：東京都',
    localityExample: '例：渋谷区',
    streetAddressExample: '例：道玄坂1-10-7',
    buildingNameExample: '例：五島育英会ビル3F',
    telExample: '例：03-3477-6025（半角）'
  },
  routeTitles: {
    documentRequest: {
      client: 'Document request for client',
      corporateCustomer: 'Document request form for corporate customers'
    },
    visitReservation: {
      name: 'Visit reservation form'
    },
    onlineConsultation: {
      name: 'online consultation'
    },
    trialStay: {
      name: 'Trial stay'
    },
    pointExchangeConfirm: {
      name: 'ポイント交換受付確認'
    },
    pageNotFound: 'Page not found'
  },
  documentRequest: {
    facilityInterestedTitleOptional1: 'ご興味があるのは、どちらの施設ですか？（複数選択可）',
    facilityInterestedTitleOptional2: 'ご興味があるシーズンはいつですか？（複数選択可）',
    findAboutVacationsTitle: '東急バケーションズは何でお知りになりましたか？（複数選択可）',
    stepByStep: {
      step1: '必要事項のご入力',
      step2: '入力内容のご確認',
      step3: '送信完了'
    },
    client: {
      settingsTitle: 'WEB資料請求',
      reqForMemberTitle: 'どちらの会員の資料請求をされますか。',
      reqForMemberData: {
        primeMember: 'バケーションマスター',
        vacationsClubMember: 'バケーションズクラブ会員',
        bothHope: 'どちらも希望'
      }
    },
    corporateCustomer: {
      settingsTitle: 'Corporate customer'
    },
    visitReservation: {
      settingsTitle: 'Visit reservation'
    },
    onlineConsultation: {
      settingsTitle: 'Online consultation'
    },
    triaStay: {
      settingsTitle: 'Trial stay'
    }
  },
  pointExchangeConfirm: {
    settingsTitle: 'ポイント交換受付確認'
  },
  buttons: {
    saveAndClose: 'Save and close',
    cancel: 'Cancel',
    saveAndCreate: 'Save and create',
    request: 'Request',
    back: 'Back',
    confirmAndSend: 'Confirm and send',
    requestItemFill: 'Required items are not entered'
  },
  rules: {
    data: {
      type: 'Type',
      year: 'Year',
      month: 'Month',
      date: 'Date',
      postalCode: 'Post code',
      email: 'Email',
      mobileNumber: 'Mobile number',
      homePhoneNumber: 'Home phone number',
      companyName: 'Company name',
      companySize: 'Company Size',
      companyNameKana: 'Kana company name ',
      companyPicDepartment: 'Department',
      companyPicTitle: 'Job title',
      businessTypeOther: 'Business type other',
      companyPicName: 'Name of person in charge',
      companyPicKana: 'Kana name of person in charge',
      lastName: 'Last name',
      firstName: 'First name',
      kanaLastName: 'Kana last name',
      kanaFirstName: 'Kana first Name',
      cityOrCounty: 'City or county',
      streetBunch: 'Street',
      addressOther: 'Address other',
      birthday: 'Birthday',
      inputOther: 'Other',
      prefecture: 'Prefecture',
      businessType: 'Business type',
      phoneNumber: 'Phone number',
      katakana: 'フリガナ'
    },
    required: '{title} is required',
    minimumNCharacter: 'Must be minimum {value} character',
    minimumN: 'Must be minimum {value}',
    moreThanN: 'Must be more than {value}',
    invalid: '{title} is not valid',
    selected: 'At least one item should be selected',
    postCodeValid: 'Please enter the zip code in 7 characters.',
    homeNumberValid: 'The format of your home phone number is incorrect.',
    corporateNumberValid: '電話番号が不正な形式です',
    mobileNumberValid: 'The format of the mobile phone number is incorrect.',
    katakana: '{title}は全角カナでご入力下さい',
    policyAgree: 'ご確認いただきご同意いただく必要がございます'
  },
  toast: {
  }
}

import { ENUM_BUSINESS_TYPE_REQUEST_GQL, ENUM_PREFECTURE_REQUEST_GQL } from '@/api/graphql/main'
import { gqlErrorToast } from '@/utils/graphqlErrors'
import { FACILITY_INTERESTED_LIST_GQL, PUBLIC_FACILITY_LIST_GQL } from '@/api/graphql/facility'
import request from '@/utils/request'

export default {

  async fetchEnumPrefectureListDispatch (context, { apolloClient, toast }) {
    apolloClient.mutate({
      mutation: ENUM_PREFECTURE_REQUEST_GQL,
      variables: {
      },
      update: (store, { data: { enumPrefectureList } }) => {
        // None
      }
    }).then(res => {
      context.commit('enumPrefectureListMutation', res?.data.enumPrefectureList ? res.data.enumPrefectureList : [])
    }).catch(error => {
      gqlErrorToast(toast, error)
    })
  },
  async fetchEnumBusinessTypeListDispatch (context, { apolloClient, toast }) {
    apolloClient.mutate({
      mutation: ENUM_BUSINESS_TYPE_REQUEST_GQL,
      variables: {
      },
      update: (store, { data: { enumBusinessTypeList } }) => {
        // None
      }
    }).then(res => {
      context.commit('enumBusinessTypeListMutation', res?.data.enumBusinessTypeList ? res.data.enumBusinessTypeList : [])
    }).catch(error => {
      gqlErrorToast(toast, error)
    })
  },
  async fetchPublicFacilityListDispatch (context, { apolloClient, toast }) {
    apolloClient.mutate({
      mutation: PUBLIC_FACILITY_LIST_GQL,
      variables: {
      },
      update: (store, { data: { publicFacilityList } }) => {
        // None
      }
    }).then(res => {
      context.commit('publicFacilityListMutation', res?.data.publicFacilityList ? res.data.publicFacilityList : [])
    }).catch(error => {
      gqlErrorToast(toast, error)
    })
  },
  async fetchFacilityInterRestedListDispatch (context, { apolloClient, toast }) {
    apolloClient.mutate({
      mutation: FACILITY_INTERESTED_LIST_GQL,
      variables: {
      },
      update: (store, { data: { facilityInterestedList } }) => {
        // None
      }
    }).then(res => {
      context.commit('facilityInterestedListMutation', res?.data.facilityInterestedList ? res.data.facilityInterestedList : [])
    }).catch(error => {
      gqlErrorToast(toast, error)
    })
  },
  fetchPostalCodeDispatch ({ commit, state, rootState, dispatch }, { payload, totast }) {
    return request({}, true).get(`/search?zipcode=${payload}`).then(res => {
      if (!res?.results || (Array.isArray(res?.results) && res.results.length === 0)) {
        totast.info('お探しの郵便番号が見つかりませんでした。ご確認の上もう一度お試しいただくか、お手数ですが手動でご入力下さい。')
        return null
      }

      return res?.results[0]
    }).catch(() => {
      totast.error('現在郵便番号検索が行えません。時間をおいて再度お試しいただくか、お手数ですが手動でご入力下さい。')
      return null
    })
  }
}

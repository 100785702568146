import actions from './actions'

const index = {
  state: {
    isConfirmReceiveOfPointExchange: false
  },
  mutations: {
    confirmReceiveOfPointExchangeMutation (state, payload) {
      state.isConfirmReceiveOfPointExchange = payload
    }
  },
  actions
}

export default index

import gql from 'graphql-tag'

export const ENUM_PREFECTURE_REQUEST_GQL = gql`
  query enumPrefectureList {
    enumPrefectureList {
      id
      name
    }
  }
`

export const ENUM_BUSINESS_TYPE_REQUEST_GQL = gql`
  query enumBusinessTypeList {
    enumBusinessTypeList {
      id
      name
    }
  }
`

<template>
  <v-container>
    <v-main>
      <div class="main-layout-container">
        <slot/>
      </div>
    </v-main>
  </v-container>
</template>

<script>

export default {
  name: 'MainLayout',
  components: {
  },
  data: () => {
    return {}
  },
  mounted () {
  },
  methods: {}
}
</script>

<style scoped>
.main-layout-container.sidebar-open {
  width: calc(100% - 56px);
  margin-left: 56px;
}
</style>

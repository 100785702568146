import actions from './actions'
import {
  funcStructureDataFormCorporateCustomerStepByStep,
  funcStructureDataFormClientStepByStep,
  funcStructureDataFormVisitReservationStepByStep,
  funcStructureDataFormOnlineConsultationStepByStep,
  funcStructureDataFormTrialStayStepByStep
} from '@/utils/structure-data'

const index = {
  state: {
    stepClientDocRequestType: '',
    stepCorporateCustomerType: '',
    stepOnlineConsultationType: '',
    stepVisitReservationType: '',
    stepTrialStayType: '',
    dataClientDocRequest: funcStructureDataFormClientStepByStep({}),
    dataCorporateCustomer: funcStructureDataFormCorporateCustomerStepByStep({}),
    dataOnlineConsultation: funcStructureDataFormOnlineConsultationStepByStep({}),
    dataVisitReservation: funcStructureDataFormVisitReservationStepByStep({}),
    dataTrialStay: funcStructureDataFormTrialStayStepByStep({})
  },
  mutations: {
    stepClientDocRequestTypeMutation (state, payload) {
      state.stepClientDocRequestType = payload
    },
    stepCorporateCustomerTypeMutation (state, payload) {
      state.stepCorporateCustomerType = payload
    },
    stepTrialStayTypeMutation (state, payload) {
      state.stepTrialStayType = payload
    },
    stepOnlineConsultationTypeMutation (state, payload) {
      state.stepOnlineConsultationType = payload
    },
    stepVisitReservationTypeMutation (state, payload) {
      state.stepVisitReservationType = payload
    },
    dataFormDocRequestMutation (state, payload) {
      state.dataClientDocRequest = payload
    },
    dataCorporateCustomerMutation (state, payload) {
      state.dataCorporateCustomer = payload
    },
    dataVisitReservationMutation (state, payload) {
      state.dataVisitReservation = payload
    },
    dataOnlineConsultationMutation (state, payload) {
      state.dataOnlineConsultation = payload
    },
    dataTrialStayMutation (state, payload) {
      state.dataTrialStay = payload
    }
  },
  actions
}

export default index

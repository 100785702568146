import MainComponent from '../../components/MainComponent'
import documentRequest from './documentRequest'
import pointExchangeConfirmRouter from '@/router/modules/pointExchangeConfirm'

const indexRouter = {
  path: '',
  component: MainComponent,
  children: [
    { path: '/', redirect: '/document-request' },
    {
      path: 'maintenance',
      name: 'maintenance',
      meta: { inMaintenance: true },
      component: () => import('@/views/Error/Maintenance')
    },
    ...documentRequest,
    ...pointExchangeConfirmRouter
  ]
}

export default indexRouter

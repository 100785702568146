import { webRequestTypeEnum } from '@/utils/enums'

export const StructureDataFindAboutVacation = function (payload = {}) {
  this.finds = payload?.finds ? payload.finds : []
  this.findBySNS = payload?.findBySNS ? payload.findBySNS : []
  this.findByInputOther = payload?.findByInputOther ? payload.findByInputOther : ''
}

/**
 * StructureData
 * @param payload
 * @constructor
 */
const StructureDataFormClientStepByStep = function (payload = {}) {
  const findAboutVacation = new StructureDataFindAboutVacation(payload?.findAboutVacation || {})

  this.type = webRequestTypeEnum.Brochure
  this.requestForMember = payload?.requestForMember ? payload.requestForMember : {} // Object
  this.mainFormDocRequest = payload?.mainFormDocRequest ? payload.mainFormDocRequest : {}
  this.facilityInterestedOption1 = payload?.facilityInterestedOption1 ? payload.facilityInterestedOption1 : []
  this.facilityInterestedOption2 = payload?.facilityInterestedOption2 ? payload.facilityInterestedOption2 : []
  this.findAboutVacation = findAboutVacation
  this.policyAgree = payload?.policyAgree ? payload.policyAgree : false
}

export const funcStructureDataFormClientStepByStep = (payload = {}, setting) => {
  return new StructureDataFormClientStepByStep(payload, setting)
}

/**
 * StructureData
 * @param payload
 * @constructor
 */
const StructureDataFormCorporateCustomerStepByStep = function (payload = {}) {
  const findAboutVacation = new StructureDataFindAboutVacation(payload?.findAboutVacation || {})

  this.type = webRequestTypeEnum.Brochure
  this.requestForCorporate = payload?.requestForCorporate ? payload.requestForCorporate : {} // Object
  this.mainFormCorporate = payload?.mainFormCorporate ? payload.mainFormCorporate : {}
  this.facilityInterestedOption1 = payload?.facilityInterestedOption1 ? payload.facilityInterestedOption1 : []
  this.facilityInterestedOption2 = payload?.facilityInterestedOption2 ? payload.facilityInterestedOption2 : []
  this.findAboutVacation = findAboutVacation
  this.policyAgree = payload?.policyAgree ? payload.policyAgree : false
}

export const funcStructureDataFormCorporateCustomerStepByStep = (payload = {}, setting) => {
  return new StructureDataFormCorporateCustomerStepByStep(payload, setting)
}

/**
 * StructureData
 * @param payload
 * @constructor
 */
const StructureDataFormOnlineConsultationStepByStep = function (payload = {}) {
  this.type = webRequestTypeEnum.OnlineCounseling
  this.mainFormDocRequest = payload?.mainFormDocRequest ? payload.mainFormDocRequest : {}
  this.onlineConsultationOptional = payload?.onlineConsultationOptional ? payload.onlineConsultationOptional : {}
  this.policyAgree = payload?.policyAgree ? payload.policyAgree : false
}

export const funcStructureDataFormOnlineConsultationStepByStep = (payload = {}, setting) => {
  return new StructureDataFormOnlineConsultationStepByStep(payload, setting)
}

/**
 * StructureData
 * @param payload
 * @constructor
 */
const StructureDataFormVisitReservationStepByStep = function (payload = {}) {
  this.type = webRequestTypeEnum.Visit
  this.mainFormDocRequest = payload?.mainFormDocRequest ? payload.mainFormDocRequest : {}
  this.visitReservationOptional = payload?.visitReservationOptional ? payload.visitReservationOptional : {}
  this.policyAgree = payload?.policyAgree ? payload.policyAgree : false
}

export const funcStructureDataFormVisitReservationStepByStep = (payload = {}, setting) => {
  return new StructureDataFormVisitReservationStepByStep(payload, setting)
}

/**
 * StructureData
 * @param payload
 * @constructor
 */
const StructureDataFormTrialStayStepByStep = function (payload = {}) {
  this.type = webRequestTypeEnum.TrialStay
  this.mainFormDocRequest = payload?.mainFormDocRequest ? payload.mainFormDocRequest : {}
  this.trialStayOptional = payload?.trialStayOptional ? payload.trialStayOptional : {}
  this.policyAgree = payload?.policyAgree ? payload.policyAgree : false
}

export const funcStructureDataFormTrialStayStepByStep = (payload = {}, setting) => {
  return new StructureDataFormTrialStayStepByStep(payload, setting)
}

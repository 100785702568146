import actions from './actions'

const index = {
  state: {
    enumPrefectures: [],
    enumBusinessTypeList: [],
    publicFacilityList: [],
    facilityInterestedList: []
  },
  mutations: {
    enumPrefectureListMutation (state, payload) {
      state.enumPrefectures = payload
    },
    enumBusinessTypeListMutation (state, payload) {
      state.enumBusinessTypeList = payload
    },
    publicFacilityListMutation (state, payload) {
      state.publicFacilityList = payload
    },
    facilityInterestedListMutation (state, payload) {
      state.facilityInterestedList = payload
    }
  },
  actions
}

export default index

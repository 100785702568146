import gql from 'graphql-tag'

export const CREATE_WEB_REQUEST_GQL = gql`
  mutation callCreateWebRequest ($data: WebRequestInput!, $medias: [WebRequestMediaInput!]) {
    createWebRequest(data: $data, medias: $medias) {
      id
      type
      memberId
    }
  }
`

import { isInMaintenance } from '@/utils/app'

export default function checkMaintenance (to, _from, next) {
  if (to.meta.inMaintenance) {
    // if in the maintenance page and maintenance finished, redirect user to the main page
    if (!isInMaintenance()) {
      return next('/')
    }
  } else {
    // if not in the maintenance page and is under maintenance, redirect user to the maintenance page
    if (isInMaintenance()) {
      return next('/maintenance')
    }
  }

  return next()
}

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { currency } from './utils/filters'
import { i18n } from './plugins/i18n'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import { createProvider } from './plugins/apollo'

Vue.config.productionTip = false

Vue.filter('currency', currency)

Vue.use(VueToast, {
  position: 'bottom-right',
  duration: 5000
})

Vue.use({
  install (Vue) {
    Vue.prototype.$env = {
      isProd: process.env.NODE_ENV === 'production',
      isStg: process.env.NODE_ENV === 'staging',
      isDev: process.env.NODE_ENV === 'development'
    }
  }
})

new Vue({
  router,
  store,
  vuetify,
  apolloProvider: createProvider(),
  i18n,
  render: h => h(App)
}).$mount('#app')

<template>
  <v-app>
    <environment-banner />
    <router-view></router-view>
  </v-app>
</template>

<script>
import EnvironmentBanner from '@/components/Alert/EnvironmentBanner'

export default {
  name: 'App',
  components: { EnvironmentBanner },
  created () {
    document.title = '東急バケーションズ Web申込ページ'
  }
}
</script>

<style lang="scss">
  // Import main style
  @import "assets/styles/index.scss";
  @import "assets/styles/comon.scss";
</style>
